.cover {
  padding: 2px 10px 2px 2px;
  margin: 0px;
  max-height: 100px
}

.cover a img {
  max-height: 100px;
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.read-button {
  text-align: center;
    border: solid;
    border-width: 1px;
    margin-right: 10px;
    margin-left: 2px;
    border-radius: 4px;
    color: #4f7ff8;

}
.bookbox {
	box-sizing: border-box;
    cursor: pointer;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 0;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.16), 0 0 0px 0 rgba(0,0,0,.12);
    display: flex;
}

.caption {
  font-family: sans-serif;
}

.author {
  font-family: sans-serif; 
  font-size: 90%;
  /*text-decoration: underline;*/
  color: #4f7ff8;
  font-style: italic;
}
.bookSearch {
  font-family: sans-serif;
  /*font-family: 'EB Garamond', serif; */
}

.bookImg > a img {
/*	
  float:left;
	padding-left:50px;
	padding-right:35px;
*/
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.bookDesc {
  font-family: 'EB Garamond', serif; 
	text-align: center;
	padding-top:25px;
  font-size:22pt;
  line-height: 30px;
  color: black;
}
.bookText {
  font-family: 'EB Garamond', serif; 
}

.bookText strong {
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: yellow;
  background-color:#ff0;
  background-opacity: 0.8;
  font-style: italic;
}

.caption em, .caption b {
	font-weight: bold;
  /*text-decoration: underline;
  text-decoration-color: yellow;*/
  background-color:#ff0;
  background-opacity: 0.8;
  font-style: italic;
}


body {
	font-family: 'EB Garamond', serif; 
}
.mui-textfield > input, .mui-btn, .mui--text-subhead, .mui-panel > .mui--text-headline {
  font-family: 'Open Sans', sans-serif;
}

.all-caps { text-transform: uppercase; }
.app-container { padding: 16px; }
.search-results em { font-weight: bold; }
.book-modal > button { width: 100%; }
.search-results .mui-divider { margin: 14px 0; }

.search-results {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.search-results > div {
  flex-basis: 45%;
  box-sizing: border-box;
  cursor: pointer;
}

@media (max-width: 600px) {
  .search-results > div { flex-basis: 100%; }
}

.paragraphs-container {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 48px;
}

.paragraphs-container .mui--text-body1, .paragraphs-container .mui--text-body2 {
  font-size: 1.8rem;
  line-height: 35px;
}

.book-modal {
  width: 100%;
  height: 100%;
  padding: 40px 10%;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: white;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
}

.pagination-panel {
  display: flex;
  justify-content: space-between;
}

.title-row {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

@media (max-width: 600px) {
  .title-row{ 
    flex-direction: column; 
    text-align: center;
    align-items: center
  }
}

.locations-label {
  text-align: center;
  margin: 8px;
}

.modal-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 99%;
  display: flex;
  justify-content: space-around;
  background: white;
}

@media screen and (max-width: 375px) {
  .cover a img {
      max-height: 50px;
  }

  .detail {
    line-height: 140%;
  }
}
.word-header {
  text-align: center;
  padding: 5px;
}
.word-bank-view {
    font-family: 'Alternate Gothic No.2';

  .count {
    font-size: 20px
  }
}
.word-click {
  text-decoration: underline;
  cursor: pointer;
  color: #333;
  font-family: 'Alternate Gothic No.2';
  text-decoration-thickness: 0.1px;
  text-decoration-color: #999;
}
.quiz-root {
  position: relative;
}
.quiz-background {
  width: 800px;
  height: 400px;
  padding-top: 200px;
  
    .question {
    font-family: "Open Sans";
    text-align: center;
    margin-top: 30px;
    height: 70px;
    // padding: 28px;
    // color: #fff;
    // background-color: #4285f4;
    // max-width: 600px;
    word-break: break-word;
    overflow: hidden;
    &.word {
//       margin-top: 57px;
      font-size: 40px;
      background: #bc69e4;
    }
  }
}
.quiz-chart {
  max-width: 600px;;
}
.desc {
  padding: 3px;
}
.word-bank-container {
  position: static;
  display: flex;
  align-items: center;
  flex-direction: column;

  #banner-side-definition {
    width: 100%;
    text-align: right;
    height: 20px;
    top: -30px;
    position: relative;
    padding-right: 20px;
  }
}
.container {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  .definition {
    top: initial;
    left: initial;
    right: 30px;
  }
  button {
    border: 0;
    min-width: 200px;
    height: 70px;
    margin: 20px 0;
    padding: 10px;
    text-align: center;
    font-family: "Open Sans";
    font-size: 30px;
    font-weight: 500;
    transition: 0.3s background-color ease-out;
    cursor: pointer;
  }

  .done-button {
    color: #333;
  }

  .question {
    background-color: red;
    font-family: "Open Sans";
    text-align: center;
    padding: 20px;
    color: #fff;
    background-color: #4285f4;
    max-width: 600px;
    word-break: break-word;

    &.word {
//       margin-top: 57px;
      font-size: 40px;
      background: #bc69e4;
    }
  }

  .status-text {
    font-family: "Open Sans";
    font-size: 16px;
    min-width: 300px;
    max-width: 500px;
    word-break: break-word;
  }
  .status-box {
    position: absolute;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-color: #ccc;
    border-style: solid;
    border-width: thin;
    padding: 30px;
    z-index: 10000;

    &.done {
      top: 60px;
      img {
        max-height: 50vh;
      }
    }

  }
  .off {
    left: -1000px;
    top: 0px;
  }
  .on {
    top: calc(50vh - 100px);
    left: calc(50vw - 350px);
  }

  .progress {
    font-family: "Open Sans";
    font-size: 18px;
    padding: 20px;
    color: #888;
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
      width: 25vw;
      text-align: center;
      padding: 0 10px;
    }

    .score {
      text-align: right;
      font-size: 14px;
      color: #bc69e4;
    }
  }

  .flex {
    position: absolute;
    margin-top: 30px;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button {
    border: 0;
    min-width: 200px;
    height: 70px;
    color: #eee;
    margin: 20px 0;
    padding: 10px;
    text-align: center;
    font-family: "Open Sans";
    font-size: 30px;
    font-weight: 500;
    transition: 0.3s background-color ease-out;
    cursor: pointer;
  }

 
}
.App {
  /*background: #fefefe url('./assets/dot.svg') repeat 0 0;*/ }

.hidden {
  display: none !important; }

#joinButton {
  cursor: pointer;
  background: -webkit-linear-gradient(left, #007bff, #4f7ff8);
  background: linear-gradient(to right, #007bff, #4f7ff8); }

.App-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.round-button {
  border-radius: 8px;
  padding: 5px 10px 8px 10px;
  cursor: pointer; }

.donate-button {
  margin-top: 12px;
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 600;
  background-color: #007bff;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 0.5px #ccc; }

.clear {
  background-color: transparent;
  border-color: transparent; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.App-intro {
  font-size: large; }

.suggestion-item {
  cursor: pointer;
  letter-spacing: 1px;
  width: 240px;
  margin: 0;
  background: white;
  /*color: #272727;*/
  color: #999;
  height: 26px;
  padding: 10px 40px 10px 20px;
  font-family: "Alternate Gothic No.2", Impact, 'Arial Narrow', Futura, sans-serif;
  text-transform: uppercase;
  font-size: 24px;
  vertical-align: baseline;
  display: block;
  /*  float: left;
  position: relative;
*/
  left: 0px;
  /*used to be 80px*/
  top: 0px;
  /*used to be -5px */
  outline: none; }

.search-suggest {
  position: absolute;
  z-index: 1000;
  padding: 0px;
  margin: 0px; }

.search-suggest > li {
  display: block;
  background-color: white; }

.bold {
  font-weight: bold; }

.definition {
  display: block; }

.banner-side-example {
  font-style: italic;
  margin-top: 5px; }

.banner-side-bottom {
  position: absolute;
  left: -10px;
  bottom: -10px; }

#banner-side {
  width: 200px; }

.display-none {
  display: none; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.purple {
  color: #fff;
  background-color: #bc69e4; }

.purple:hover {
  color: #bc69e4;
  background-color: #fff; }

.green {
  color: #fff;
  background-color: #34a853; }

.green:hover {
  background-color: #1e8e3e; }

.red {
  color: #fff;
  background-color: #ea4335; }

.red:hover {
  background-color: #c4211f; }

.yellow {
  color: #fff;
  background-color: #fbbc04; }

.yellow:hover {
  background-color: #f29900; }

.blue {
  color: #fff;
  background-color: #4285f4; }

.blue:hover {
  background-color: #1967d2; }

.inverted-green {
  background-color: #fff;
  color: #34a853; }

.inverted-red {
  background-color: #fff;
  color: #ea4335; }

.inverted-yellow {
  background-color: #fff;
  color: #fbbc04; }

.inverted-blue {
  background-color: #fff;
  color: #4285f4; }

body {
  /*background-color: #EDEDED;*/
  /*background-color: #f1f1f1;*/
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif; }

#toolbar {
  /*height: 35px;
width: 100%
background-color: #3D3D3D;
background-image: url(https://b9.sustatic.com/_DpBT92r-8P7_RjkjFvSWw),-webkit-linear-gradient(top,#3D3D3D 0%,#212121 100%);
-webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, .25);
-moz-box-shadow: 0 2px 3px rgba(0,0,0,.25);
box-shadow: 0 2px 3px rgba(0, 0, 0, .25);
*/
  /**** NEW STYLE ****/
  height: 90px;
  width: 100%;
  background-color: #3D3D3D;
  background-image: url(https://b9.sustatic.com/_DpBT92r-8P7_RjkjFvSWw), -webkit-linear-gradient(top, #3D3D3D 0%, #212121 100%);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  background: url("https://www.waze.com/images/body_bg.png") repeat-x; }

ul {
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 40px;
  display: inline; }

li {
  display: inline;
  list-style-type: none;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 12px;
  padding-left: 22px; }

a {
  text-decoration: none; }

#toolbar-left {
  display: none; }

#toolbar-right {
  display: block;
  /*background-image: url("https://www.waze.com/images/nav/logo_new.png");*/
  /*content: "We Define."*/
  background-repeat: no-repeat;
  height: 90px;
  float: left;
  margin: 18px 11px 0px 10px;
  position: relative;
  left: 160px; }

#toolbar-right li {
  font-family: 'Varela Round', sans-serif;
  font-size: 55px;
  color: white; }

#banner-side-searcher-result {
  text-align: left;
  overflow: auto;
  height: 275px; }

.content-searcherwords {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.content-searcherwords a {
  color: #6d6e71;
  line-height: 160%; }

#toolbar-right li.caption {
  font-family: 'Varela Round', sans-serif;
  font-size: 14px;
  color: #B7E1ED;
  width: 200px;
  text-wrap: normal;
  display: block;
  position: absolute;
  top: 18px;
  left: 320px;
  font-weight: 800; }

#toolbar-left .tb-button-text {
  padding-left: 3px;
  line-height: 35px;
  /*color: #CCC;*/
  color: #aaa;
  text-shadow: 0 -1px 1px black; }

.searcherResult {
  font-style: italic;
  margin-top: 5px; }

/*** catchy button ***/
.btn.primary.cta {
  min-width: 280px;
  position: relative;
  top: -15px;
  left: 100px;
  line-height: 75px;
  border-radius: 60px;
  font-size: 30px;
  white-space: nowrap;
  -o-box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2), 0 0 0 10px #B8B8B8;
  -ms-box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2), 0 0 0 10px #B8B8B8;
  box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2), 0 0 0 10px #B8B8B8; }

.btn.primary {
  color: white;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.29);
  font-weight: bold;
  border: 1px solid #9D3021;
  background-color: #EB4924;
  background-image: linear-gradient(bottom, #B32C0D 0%, #EB4924 100%);
  background-image: -webkit-linear-gradient(bottom, #B32C0D 0%, #EB4924 100%);
  background-image: -ms-linear-gradient(bottom, #B32C0D 0%, #EB4924 100%);
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #B32C0D), color-stop(1, #EB4924));
  /* blue
border: 1px solid #113F99;
background-color: #0096D6;
background-image: linear-gradient(bottom,#113F99 0%,#0096D6 100%);
background-image: -o-linear-gradient(bottom,#113F99 0%,#0096D6 100%);
background-image: -moz-linear-gradient(bottom,#113F99 0%,#0096D6 100%);
background-image: -webkit-linear-gradient(bottom,#113F99 0%,#0096D6 100%);
background-image: -ms-linear-gradient(bottom,#113F99 0%,#0096D6 100%);
background-image: -webkit-gradient(linear,left bottom,left top,color-stop(0,#113F99),color-stop(1,#0096D6) );
*/ }

.btn {
  min-width: 87px;
  margin: 0;
  padding: 10px 10px;
  display: inline-block;
  cursor: pointer;
  color: #888;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.39);
  border-radius: 11px;
  border: 1px solid #CECECF; }

#banner {
  max-width: 1180px;
  min-width: 960px;
  height: 468px;
  margin: 0;
  position: relative; }

.definition {
  border: 1px solid #ECECEC;
  border-radius: 10px 10px 10px 10px;
  display: block;
  max-width: 200px;
  z-index: 500;
  position: absolute;
  top: 20px;
  left: 70px;
  padding: 10px 10px 5px 10px;
  margin: 0; }

.definition ul {
  margin: 0;
  padding: 0; }

.definition li {
  font-family: "Helvetica Neue", "Helvetical", Arial, sans-serif;
  font-style: normal;
  font-size: 12px;
  display: inline-block;
  z-index: 500;
  color: #666;
  padding-left: 0; }

.definition li span {
  padding: 0px 3px 0px 3px;
  background: white;
  display: inline-block;
  color: #666;
  text-align: left;
  line-height: 12pt; }

.definition li span .byline {
  /*padding: 0px 0px 5px 0px;*/
  margin: 0;
  font-family: "Alternate Gothic No.2", Impact, 'Arial Narrow', Futura, sans-serif;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 16px;
  color: #939598;
  line-height: 1;
  vertical-align: baseline;
  outline: none;
  display: block;
  margin-bottom: 5px; }

.definition li span .byline span {
  color: #EF3E42;
  font-size: 12px;
  vertical-align: top; }

#banner-feature {
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
  /*box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);*/
  box-shadow: 0 5px 20px -10px rgba(0, 0, 0, 0.2), 0 -5px 20px -10px rgba(0, 0, 0, 0.2);
  border-radius: 4px; }

#banner {
  margin: auto;
  z-index: 0; }

#banner-side {
  width: 200px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 450;
  /*background: black;*/
  border-left: 1px solid #ebebeb;
  /*
  background-image: -webkit-linear-gradient(top, white 0%, #EBEBEC 100%);
background-image: -ms-linear-gradient(top, white 0%, #EBEBEC 100%);
background-image: linear-gradient(top, white 0%, #EBEBEC 100%);
*/
  /*background: #ebebec;*/ }

#banner-side-top {
  top: 0;
  right: 0;
  margin: 5px 0px 10px; }

/*  PREVIOUS DESIGN
.byline {
font-size: 36px;
color: #939598;
line-height: 1;
font-weight: normal;
font-family: "StrangeloveTextRegular", "Alternate Gothic No.2", Impact, "Ariel Narrow", Helvetica, Arial, sans-serif;
padding-left: 19px;
}
*/
/* BIG HEADLINE VERSION
.byline {
  padding: 10px 0 8px 0;
margin-bottom: 0;
overflow: hidden;
color: #3B3B3B;
font-size: 22px;
line-height: 1.3;
font-weight: normal;
text-shadow: 0 0 0 transparent, 1px 1px 2px white;
font-family: 'Oswald',sans-serif;
margin-left: 10px;
}
*/
#banner-side .byline {
  /*background-color: #2a6584;*/
  /*background-color: #ED258F;*/
  height: 25px;
  padding: 0 10px;
  margin-bottom: 4px;
  display: block;
  line-height: 25px;
  font-size: 14px;
  font-weight: normal;
  text-shadow: 0 0 0 transparent, 0 0 1px rgba(0, 0, 0, 0.1);
  color: white;
  font-family: 'Oswald',sans-serif;
  margin-left: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25); }

.byline span {
  color: #EF3E42; }

.manifesto {
  display: block;
  width: 170px;
  padding: 10px;
  border: none;
  text-decoration: none;
  color: #D6DEE0;
  opacity: 1; }

.manifesto span {
  font-size: 12px;
  display: block;
  line-height: 1.3;
  margin-top: 5px; }

.manifesto.wl span {
  font-size: 12px;
  color: #bc69e4;
  line-height: 1.3;
  margin-top: 5px; }

.vocab-def {
  position: absolute;
  left: 200px; }

.wordlist-div {
  position: absolute;
  left: 200px; }

#vocabDefs {
  margin-bottom: 8px;
  text-shadow: 0 0 0 transparent, 1px 1px 1px white;
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6D6E71; }

#banner-side-definition {
  margin-bottom: 8px;
  text-shadow: 0 0 0 transparent, 1px 1px 1px white;
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6D6E71;
  height: 80px; }

#banner-side-definition:first-letter {
  text-transform: uppercase; }

#banner-side-bottom {
  right: 0;
  background: transparent; }

#menu-primary {
  position: relative;
  top: 0;
  left: 0; }

#menu-primary .wrap {
  max-width: 1180px;
  min-width: 960px;
  margin: 0;
  z-index: 600;
  /*background: #D1D3D4;*/
  position: relative;
  height: 30px;
  /* white
background: -moz-linear-gradient(top,white 80%,#F6F6F6 100%);
background: -webkit-gradient(linear,left top,left bottom,color-stop(80%,white),color-stop(100%,#F6F6F6));
background: -webkit-linear-gradient(top,white 80%,#F6F6F6 100%);
background: -o-linear-gradient(top,white 80%,#F6F6F6 100%);
background: -ms-linear-gradient(top,white 80%,#F6F6F6 100%);
background: linear-gradient(to bottom,white 80%,#F6F6F6 100%);
*/
  /* same as top toolbar */
  background-color: #3D3D3D;
  background-image: url(https://b9.sustatic.com/_DpBT92r-8P7_RjkjFvSWw), -webkit-linear-gradient(top, #3D3D3D 0%, #212121 100%);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  /* gray 
border-top: 1px solid white;
background-color: #A6A7A8;
background-image: -moz-linear-gradient(top, #BCBEC0 0%, #979797 100%);
background-image: -webkit-linear-gradient(top, #BCBEC0 0%, #979797 100%);
background-image: -ms-linear-gradient(top, #BCBEC0 0%, #979797 100%);
background-image: linear-gradient(top, #BCBEC0 0%, #979797 100%);
*/
  text-align: center;
  border-bottom: 1px solid #D4D4D4; }

#menu-primary .menu {
  max-width: 1180px;
  min-width: 960px;
  background: #D1D3D4;
  zoom: 1;
  height: 1%;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25); }

#menu-primary ul {
  margin: 0;
  padding: 0 0 0 50px;
  list-style: none;
  line-height: 1.0;
  overflow: hidden;
  height: 38px;
  border-bottom: 1px solid #babbbe; }

/*Top Level*/
#menu-primary li {
  float: left;
  width: 172px;
  position: relative;
  margin-right: 19px; }

#menu-primary li a {
  display: block;
  position: relative;
  /*  color: #565C5F; for white background*/
  color: #8e8e8e;
  /*same as top toolbar */
  border: none;
  border-top: 1px solid #b5b5b6;
  text-decoration: none;
  outline: none;
  margin-top: 7px;
  padding-top: 6px;
  font-weight: normal;
  font: normal normal normal 12px/12px Arial, Verdana, sans-serif;
  text-transform: uppercase; }

#menu-primary li a:hover {
  text-decoration: none;
  color: #D6DEE0;
  border-top: 1px solid #ef3e42; }

#menu-primary li li a:hover {
  text-decoration: none;
  color: #565C5F;
  border-top: none;
  border-bottom: 1px solid #ef3e42; }

.page-divider {
  height: 1px;
  width: 100%;
  background-color: #FEFEFE;
  border-top: 1px solid #DEDEDE;
  text-align: center;
  z-index: 1; }

#main {
  max-width: 1130px;
  min-width: 910px;
  margin: auto;
  padding: 0 0 25px 50px;
  clear: both;
  overflow: hidden;
  /*background: #F1F1F1;  shadow on top will disappear if we include this */
  position: relative;
  margin-top: 0;
  padding-bottom: 0;
  border-top: 1px solid #DEDEDE;
  border-radius: 4px;
  /* used to be 0 1px 2px  and 0 3 5 with no inset*/
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.07); }

#main #content {
  width: 64%;
  border-right: 1px solid #BABBBE;
  float: left;
  padding: 27px 21px 27px 0;
  /*background: transparent url("../images/column.gif") repeat-y scroll 49% 0;
*/ }

#main .post {
  width: 45%;
  float: left;
  display: inline;
  /*margin-right: 7.934336525307797%;*/
  margin-bottom: 19px;
  overflow: hidden;
  margin: 0;
  font-size: 0.8em;
  line-height: 160%;
  color: #666;
  position: relative;
  line-height: 160%; }

#post-5068 {
  position: relative;
  left: 7.9%; }

.home-cat-name {
  font-size: 19px;
  line-height: 1;
  display: inline-block;
  padding: 5px 4px 5px 0px;
  text-transform: uppercase;
  font-size-adjust: 0.5;
  color: #58595B;
  outline: 0;
  text-decoration: none;
  border: none;
  font-family: "Alternate Gothic No.2", "Arial Narrow", Helvetica, Arial, sans-serif; }

.home-cat-name:before {
  content: "|";
  color: #2a6584; }

.readmore {
  padding-left: 2px;
  text-transform: uppercase;
  /*  background: transparent url(../images/more-arrow.gif) no-repeat scroll center right;
*/
  padding-right: 13px;
  display: inline-block;
  text-decoration: none;
  border: none;
  font-family: "Alternate Gothic No.2", "Arial Narrow", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: white;
  font-weight: normal;
  margin-top: 10px;
  background-position-y: 6px; }

.content-relatedwords a {
  border-bottom: 1px dotted #EF3E42;
  color: #EF3E42;
  line-height: 160%; }

.entry-summary {
  margin-top: 7px;
  margin-bottom: 7px; }

#sidebar-primary {
  font-size: 0.8em;
  line-height: 160%;
  color: #666;
  width: 33%;
  float: right;
  padding-top: 27px;
  padding-bottom: 17px; }

#sidebar-primary .widget {
  clear: both; }

.widget-wrap {
  padding: 27px 5px 0 5px;
  /*max-width: 355px;*/ }

#sidebar-primary .widget-title {
  font-size: 19px;
  line-height: 1;
  display: inline-block;
  padding: 5px 4px 5px 0px;
  text-transform: uppercase;
  font-size-adjust: 0.5;
  color: #58595B;
  outline: 0;
  text-decoration: none;
  border: none;
  font-family: "Alternate Gothic No.2", "Arial Narrow", Helvetica, Arial, sans-serif; }

.widget-title:before {
  content: "|";
  color: #2a6584; }

#footer {
  max-width: 1103px;
  min-width: 883px;
  margin: 0;
  padding: 25px 27px 0 50px;
  overflow: hidden;
  clear: both;
  font-size: 12px;
  color: #CCC;
  float: left; }

#footer a {
  color: #CCC;
  text-decoration: none;
  border: none; }

/***********USAGE*************/
.usage#banner-feature {
  background-color: white;
  padding: 6px;
  display: block;
  float: left; }

.usage #banner-wrap {
  background-color: black;
  width: 100%;
  height: 100%; }

.usage .description-text {
  color: #f6f6f6;
  font-family: serif;
  font-size: 20px 20px 15px 20px;
  font-family: 'Arvo','Merriweather',  Georgia;
  font-size: 23px;
  padding: 20px; }

.usage .description-info {
  color: #d10451;
  font-family: 'Merriweather', 'Arvo', Georgia;
  font-size: 12px;
  padding: 0 20px 20px 20px; }

.usage .description-info a {
  text-decoration: none;
  color: #d10451;
  font-family: 'Merriweather', 'Arvo', Georgia;
  font-size: 12px; }

.logo-test {
  width: 40px;
  height: 8px;
  background-color: white;
  border: 3px solid red;
  border-radius: 10px 10px; }

.logo-book {
  width: 75px;
  height: 40px;
  float: left;
  /*  background-image: url("../images/logo-booksmall.png")
*/ }

.content-primary {
  border-top: 3px solid white; }

#tree-icon {
  width: 16px;
  height: 16px;
  display: block;
  /*background: #2a6584;*/
  background: #4f7ff8;
  cursor: pointer;
  text-indent: -9999px;
  border-radius: 20px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 20px;
  left: 50px;
  /*
position: absolute;
bottom: 12px;
left: 50px;
font-family: "Varela Round";
color: #2a6584;
font-size: 40px;
font-weight: 100;

width: 30px;
height: 30px;
display: block;
cursor: pointer;
-webkit-border-radius: 30px;
-moz-border-radius: 30px;
-o-border-radius: 30px;
border-radius: 30px;
box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
padding-bottom: 0px;
*/ }

#star-icon {
  width: 14px;
  height: 14px;
  display: block;
  background: #eee;
  cursor: pointer;
  text-indent: -9999px;
  border-radius: 20px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 20px;
  left: 80px;
  border-color: #4f7ff8;
  border-width: 1.5px;
  border-style: solid;
  opacity: 0.6;
  /*
position: absolute;
bottom: 12px;
left: 90px;
font-family: "Valera Round";
color: silver;
font-size: 50px;

width: 30px;
height: 30px;
display: block;
cursor: pointer;
-webkit-border-radius: 50px;
-moz-border-radius: 50px;
-o-border-radius: 50px;
border-radius: 50px;
box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
padding-bottom: 0px;
*/ }

@font-face {
  font-family: 'Trump Gothic East';
  src: url("https://born4banned.googlecode.com/files/trumpgothiceastmedium.eot");
  src: url("https://born4banned.googlecode.com/files/trumpgothiceastmedium.woff") format("woff"), url("https://born4banned.googlecode.com/files/trumpgothiceastmedium.ttf") format("truetype"), url("https://born4banned.googlecode.com/files/trumpgothiceastmedium.svg") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Alternate Gothic No.2';
  src: url(/static/media/ALTGOT2N.614c6b4f.ttf); }

@font-face {
  font-family: "StrangeloveTextRegular";
  src: url(/static/media/strangelove-text-webfont.57448df6.ttf); }

/*@font-face {
  font-family: "Wendy";
  src: url('../files/wendy-lp-medium1.ttf');
}*/
/*@font-face {
  font-family: "quantrnd";
  src: url('../files/quantrnd.ttf');
}*/
@media only screen and (max-device-width: 480px), only screen and (min-device-width: 640px) and (max-device-width: 1136px) and (-webkit-min-device-pixel-ratio: 2) {
  /* iPhone only */
  #banner-side-top {
    display: none; }
  #banner-side {
    border: 0; }
  /*    a#icon-next{ top:35px; left: auto;}
*/ }

@media screen and (max-width: 425px) {
  #banner-feature {
    box-shadow: none; }
  #tree-icon {
    left: 30px; }
  #star-icon {
    left: 60px; }
  #banner {
    min-width: 370px;
    max-width: 375px;
    width: 375px;
    height: 375px;
    overflow: hidden; }
  #main {
    padding: 0px;
    min-width: 370px;
    max-width: 370px;
    width: 370px;
    font-size: 67%; }
  #definitions {
    margin-bottom: 20px; }
  #main #content {
    width: 185px;
    padding: 15px 10px 15px 0; }
  #main .post {
    width: 90%;
    font-size: 95%; }
  #post-5068 {
    position: relative;
    left: 10px; }
  #sidebar-primary {
    width: 45%;
    padding-top: 15px; }
  .readmore {
    font-size: 15px;
    line-height: 17px; }
  .entry-summary {
    line-height: 120%; }
  .content-relatedwords {
    line-height: 75%; }
  #banner-side {
    display: none; } }

.App-header-name {
  /*color: #B7E1ED;*/
  color: #4f7ff8;
  font-size: 45px;
  position: relative;
  top: 5px;
  left: 0px;
}

.App-header-caption {
  color: white;
  font-size: 14px;
  font-weight: 500;
  display:block;
  background-color: #4f7ff8;
  border-radius: 6px;
   margin-top: 16px;
   margin-right: 0px;
    padding-top: 12px;
    /*padding-left: 26px;*/
    padding-bottom: 12px;
    text-align: center;
    width:150px;
    float:right;
}

.App-header {
  font-family: 'Varela Round', sans-serif;
  /*background-color: #323232;*/
  height: 70px;
  padding: 0px;
  
  color: white;
  max-width: 1180px;
  min-width: 860px;
  margin:auto;
}

@media screen and (max-width:425px) {
  .App-header {
    height: unset;  
  }

  .App-header {
    max-width: 375px;
    min-width: 375px;
  }
}
.App-header-holder {
    /*background-color: #323232;*/

}
li.selected-item {
  background-color: #B7E1ED; }

.quiz-button {
  font-family: 'Oswald',sans-serif;
  font-size: 18px;
  padding: 4px 20px 6px 18px;
  margin-left: 40px;
  border: solid 0.5px #ccc; }
  .quiz-button:focus {
    border: solid 0.5px #ccc; }

input:focus::-webkit-input-placeholder {
  color: transparent; }

input:focus:-moz-placeholder {
  color: transparent; }

/* FF 4-18 */
input:focus::-moz-placeholder {
  color: transparent; }

/* FF 19+ */
input:focus:-ms-input-placeholder {
  color: transparent; }

/* IE 10+ */
h1.header-logo-text {
  /*background: transparent url("../images/logo-wendy.png") no-repeat center 0;*/
  height: 70px;
  width: 200px;
  text-indent: -9999px;
  float: left;
  position: relative;
  /*top: -10px;*/
  left: -20px;
  font-family: 'Wendy';
  font-size: 55px;
  color: #555;
  font-weight: 200;
  background: transparent url(/static/media/lookup.f157e9f5.png) no-repeat center 0; }

.header-content {
  max-width: 1180px;
  min-width: 860px;
  /*padding: 35px 50px 0; */
  /*used to be 50 50 0*/
  /*height: 75px;*/
  padding: 20px 50px;
  height: 50px;
  border-bottom: 1px solid #DFDFDF;
  margin: auto;
  display: -webkit-flex;
  display: flex; }

.header-logo {
  display: block;
  float: left;
  width: 200px; }

.header-content .btn .search2 .search-text {
  position: relative;
  top: 0px;
  left: 0px; }

/* black big header banner */
.header-content-wrap.clean {
  background: #373737 url(http://b9.sustatic.com/nxPUscdEbS9Bu2gmPf5Dxg) no-repeat center;
  border-bottom: 1px solid white; }

.header-content-wrap.short {
  height: auto;
  min-height: 59px;
  max-height: 244px;
  padding: 20px 0; }

/***************************/
.word-list-selector {
  margin-left: 200px; }

.search-form {
  display: inline-block;
  width: 304px; }

.search-text {
  /*border: 3px solid #A9ADA8;
border-radius: 18px 18px 18px 18px;
*/
  /*border: 3px solid #BDBDBD;
border-radius: 10px;*/
  /*border: 2px solid #1cadce;*/
  border: 2px solid #4f7ff8;
  border-radius: 10px;
  letter-spacing: 1px;
  width: 240px;
  margin: 0;
  background: white;
  /*color: #272727;*/
  color: #999;
  height: 26px;
  padding: 10px 40px 10px 20px;
  font-family: "Alternate Gothic No.2", Impact, 'Arial Narrow', Futura, sans-serif;
  text-transform: uppercase;
  font-size: 24px;
  vertical-align: baseline;
  display: block;
  /*  float: left;
  position: relative;
*/
  left: 0px;
  /*used to be 80px*/
  top: 0px;
  /*used to be -5px */
  outline: none;
  /*
padding: 0 10px 0 15px;
height: 28px;
font-size: 18px;
line-height: 28px;
font-family: "Alternate Gothic No.2", Impact, 'Arial Narrow', Futura, sans-serif;
text-transform: uppercase;
color: #999;
font-weight: normal;
background: white;

font-size: 18px;
  height: 28px;
  width: 150px;
  vertical-align: baseline;
  display: block;
  float: left;
  position: relative;
  left: 80px;
  top: 5px;
  outline: none;
*/ }

.header-content .search2 .search-text:focus {
  border: 3px solid #A9ADA8;
  border-radius: 18px 18px 18px 18px;
  box-shadow: 0px 0px 10px #6EA2DE; }

.search-submit {
  display: none; }

/*************ICONS***************/
a.next-word {
  /*background: url('https://freeiconshop.com/wp-content/uploads/edd/shuffle-flat.png') no-repeat;*/
  background: url("/common/img/shuffleiconbluesame1.png") no-repeat;
  /*background: url('http://www.thehappiestmama.com/wp-content/uploads/2020/12/nextword.png');*/
  background-size: 50px;
  z-index: 9999;
  /*background-position-x:0px;
  background-position-y:-1321px;*/
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
  zoom: 1;
  overflow: hidden;
  text-indent: -9999em;
  height: 50px;
  width: 50px;
  cursor: pointer; }

a.next-word.hover:hover {
  /*background-position-x:0px;
  background-position-y:-740px;*/
  /*transform: rotate(-70deg);*/
  /*box-shadow: 2px 5px 32px rgba(0,0,0.3);*/
  -webkit-transform: rotate(-15deg);
  transform: rotate(-10deg);
  /*opacity: 0.85;*/ }

@media screen and (max-width: 425px) {
  div.header-content {
    padding: 10px;
    margin: none;
    max-width: 375px;
    min-width: 375px;
    width: 375px; }
  .search-form {
    width: unset; }
  .search-text {
    width: 107px;
    height: 26px;
    font-size: 16px; }
  a.next-word {
    margin-left: 9px; }
  a.arrow {
    background: url("http://www.thehappiestmama.com/wp-content/uploads/2013/01/sprite-icons3.png") no-repeat;
    background-position-x: 0px;
    background-position-y: -1321px;
    right: 0;
    display: block;
    margin-top: -35px;
    position: absolute;
    top: 50%;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
    overflow: hidden;
    text-indent: -9999em;
    height: 70px;
    width: 70px;
    cursor: pointer;
    opacity: 0.3;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    right: -17.5px; }
  a.arrow:hover {
    background-position-x: 0px;
    background-position-y: -740px; } }

.rc-menu-item {
  background-color: white;
  cursor: pointer;
  color:#bc69e4;
}

.rc-menu-submenu > div.rc-menu-submenu-title {
  background-color: white;
  cursor: pointer;
  color:#bc69e4;
}

li.rc-menu-item-active, .rc-menu-submenu-active > div.rc-menu-submenu-title {
  background-color:#bc69e4;
  color: white;
}
.rc-menu-item-selected {
  background-color: #eaf8fe;
}

.demo-menu {
  margin-left: 30px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 4px #d9d9d9;
  border-radius: 3px;
  z-index:9;
}

.inline-menu {
  background-color: white;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 4px #d9d9d9;
  border-radius: 3px;
  z-index:9;
}

li {
  font-family: oswald;
  text-transform: uppercase;  
  font-size: 14px;  
}

.current-wordlist-container {
  height:100%;
  display:-webkit-flex;
  display:flex;
  padding: 0px 24px;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  color:#bc69e4;

}
.current-wordlist-container:hover {
  color:white;
  background-color:#bc69e4;
}

.current-wordlist-container:hover div {
  color:white;
  background-color:#bc69e4;
}

.current-wordlist {
  position: relative;
  margin-right:20px;
  background: white;
  border-style: solid;
  min-width: 110px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: oswald;
  text-transform: uppercase;
}

.rc-menu {
  border: none;
}

.current-wordlist-container:after {
  content: "";
  border: 10px solid transparent;
  border-top-color: #bc69e4;
  -webkit-transform: translate(0, 4px);
          transform: translate(0, 4px);
}

.current-wordlist-container:hover:after {
  border-top-color: white;

}
li.rc-menu-item i {
  display: none;
}

@media screen and (max-width:425px) {
  .current-wordlist {
    margin-right: 4px;
    min-width: 64px;
    max-width: 64px;
    font-size: 12px;
  }

  .demo-menu {
    margin: 0px 10px;
  }
  
  .current-wordlist-container {
    padding: 0px 10px;
  }
}
.cover {
  padding: 2px 10px 2px 2px;
  margin: 0px;
  max-height: 100px
}

.cover a img {
  max-height: 100px;
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.read-button {
  text-align: center;
    border: solid;
    border-width: 1px;
    margin-right: 10px;
    margin-left: 2px;
    border-radius: 4px;
    color: #4f7ff8;

}
.bookbox {
	box-sizing: border-box;
    cursor: pointer;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 0;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.16), 0 0 0px 0 rgba(0,0,0,.12);
    display: -webkit-flex;
    display: flex;
}

.caption {
  font-family: sans-serif;
}

.author {
  font-family: sans-serif; 
  font-size: 90%;
  /*text-decoration: underline;*/
  color: #4f7ff8;
  font-style: italic;
}
.bookSearch {
  font-family: sans-serif;
  /*font-family: 'EB Garamond', serif; */
}

.bookImg > a img {
/*	
  float:left;
	padding-left:50px;
	padding-right:35px;
*/
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.bookDesc {
  font-family: 'EB Garamond', serif; 
	text-align: center;
	padding-top:25px;
  font-size:22pt;
  line-height: 30px;
  color: black;
}
.bookText {
  font-family: 'EB Garamond', serif; 
}

.bookText strong {
  font-weight: bold;
  text-decoration: underline;
  -webkit-text-decoration-color: yellow;
          text-decoration-color: yellow;
  background-color:#ff0;
  background-opacity: 0.8;
  font-style: italic;
}

.caption em, .caption b {
	font-weight: bold;
  /*text-decoration: underline;
  text-decoration-color: yellow;*/
  background-color:#ff0;
  background-opacity: 0.8;
  font-style: italic;
}


body {
	font-family: 'EB Garamond', serif; 
}
.mui-textfield > input, .mui-btn, .mui--text-subhead, .mui-panel > .mui--text-headline {
  font-family: 'Open Sans', sans-serif;
}

.all-caps { text-transform: uppercase; }
.app-container { padding: 16px; }
.search-results em { font-weight: bold; }
.book-modal > button { width: 100%; }
.search-results .mui-divider { margin: 14px 0; }

.search-results {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.search-results > div {
  -webkit-flex-basis: 45%;
          flex-basis: 45%;
  box-sizing: border-box;
  cursor: pointer;
}

@media (max-width: 600px) {
  .search-results > div { -webkit-flex-basis: 100%; flex-basis: 100%; }
}

.paragraphs-container {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 48px;
}

.paragraphs-container .mui--text-body1, .paragraphs-container .mui--text-body2 {
  font-size: 1.8rem;
  line-height: 35px;
}

.book-modal {
  width: 100%;
  height: 100%;
  padding: 40px 10%;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: white;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
}

.pagination-panel {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.title-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

@media (max-width: 600px) {
  .title-row{ 
    -webkit-flex-direction: column; 
            flex-direction: column; 
    text-align: center;
    -webkit-align-items: center;
            align-items: center
  }
}

.locations-label {
  text-align: center;
  margin: 8px;
}

.modal-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 99%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  background: white;
}

@media screen and (max-width: 375px) {
  .cover a img {
      max-height: 50px;
  }

  .detail {
    line-height: 140%;
  }
}
.word-header {
  text-align: center;
  padding: 5px; }

.word-bank-view {
  font-family: 'Alternate Gothic No.2'; }
  .word-bank-view .count {
    font-size: 20px; }

.word-click {
  text-decoration: underline;
  cursor: pointer;
  color: #333;
  font-family: 'Alternate Gothic No.2';
  text-decoration-thickness: 0.1px;
  -webkit-text-decoration-color: #999;
          text-decoration-color: #999; }

.quiz-root {
  position: relative; }

.quiz-background {
  width: 800px;
  height: 400px;
  padding-top: 200px; }
  .quiz-background .question {
    font-family: "Open Sans";
    text-align: center;
    margin-top: 30px;
    height: 70px;
    word-break: break-word;
    overflow: hidden; }
    .quiz-background .question.word {
      font-size: 40px;
      background: #bc69e4; }

.quiz-chart {
  max-width: 600px; }

.desc {
  padding: 3px; }

.word-bank-container {
  position: static;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .word-bank-container #banner-side-definition {
    width: 100%;
    text-align: right;
    height: 20px;
    top: -30px;
    position: relative;
    padding-right: 20px; }

.container {
  position: absolute;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center; }
  .container .definition {
    top: auto;
    top: initial;
    left: auto;
    left: initial;
    right: 30px; }
  .container button {
    border: 0;
    min-width: 200px;
    height: 70px;
    margin: 20px 0;
    padding: 10px;
    text-align: center;
    font-family: "Open Sans";
    font-size: 30px;
    font-weight: 500;
    -webkit-transition: 0.3s background-color ease-out;
    transition: 0.3s background-color ease-out;
    cursor: pointer; }
  .container .done-button {
    color: #333; }
  .container .question {
    background-color: red;
    font-family: "Open Sans";
    text-align: center;
    padding: 20px;
    color: #fff;
    background-color: #4285f4;
    max-width: 600px;
    word-break: break-word; }
    .container .question.word {
      font-size: 40px;
      background: #bc69e4; }
  .container .status-text {
    font-family: "Open Sans";
    font-size: 16px;
    min-width: 300px;
    max-width: 500px;
    word-break: break-word; }
  .container .status-box {
    position: absolute;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    background-color: white;
    border-color: #ccc;
    border-style: solid;
    border-width: thin;
    padding: 30px;
    z-index: 10000; }
    .container .status-box.done {
      top: 60px; }
      .container .status-box.done img {
        max-height: 50vh; }
  .container .off {
    left: -1000px;
    top: 0px; }
  .container .on {
    top: calc(50vh - 100px);
    left: calc(50vw - 350px); }
  .container .progress {
    font-family: "Open Sans";
    font-size: 18px;
    padding: 20px;
    color: #888;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%; }
    .container .progress span {
      width: 25vw;
      text-align: center;
      padding: 0 10px; }
    .container .progress .score {
      text-align: right;
      font-size: 14px;
      color: #bc69e4; }
  .container .flex {
    position: absolute;
    margin-top: 30px;
    width: 800px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
  .container button {
    border: 0;
    min-width: 200px;
    height: 70px;
    color: #eee;
    margin: 20px 0;
    padding: 10px;
    text-align: center;
    font-family: "Open Sans";
    font-size: 30px;
    font-weight: 500;
    -webkit-transition: 0.3s background-color ease-out;
    transition: 0.3s background-color ease-out;
    cursor: pointer; }


#flickrlist li {
list-style: none;
margin: 0;
/*padding: 2px 2px 2px 2px;*/
float: left;
display: block;
width: 75px;
height: 75px;
border: 1px solid #CCC;
margin-right: 8px;
margin-bottom: 8px;
padding: 0px;
position: relative;
overflow: hidden;

}
#flickrlist ul {
list-style: square;
}

#flickrlist li a {
margin: 0;
padding: 0;
display: block;
width: 75px;
height: 75px;
float: left;
border: none;
text-decoration: none;
}

#flickrlist li a img{
  width: 120px;
  height: auto;
  position: absolute;
  top:0;
  left:0;
}
.node circle {
  cursor: pointer;
  /*fill: #fff;*/
  stroke: steelblue;
  stroke-width: 2px;
}

.vx1 { max-width: 80vw; background-color:  #ff3f4a; }
.vx2 { max-width: 80vw; background-color:  #ff942c; }
.vx3 { max-width: 80vw; background-color:  #e9c829; }
.vx4 { max-width: 80vw; background-color:  #a0da37; }
.vx5 { max-width: 80vw; background-color:  #63b9e3; }


circle.vx1 { fill:  #ff3f4a; stroke:  #ff3f4a;}
circle.vx2 { fill:  #ff942c; stroke:  #ff942c;}
circle.vx3 { fill:  #e9c829; stroke:  #e9c829;}
circle.vx4 { fill:  #a0da37; stroke:  #a0da37;}
circle.vx5 { fill:  #63b9e3; stroke:  #63b9e3;}

.noun {
  /*background-color: #c30;*/
  background-color:#ed258f;
}
.adj {
    /*background-color: #0070AA;*/
      background-color: #4f7ff8;

}

.adv {
  /*background-color: #C95999;*/
    background-color: #ffc107
}
.verb {
    /*background-color: #E99911;*/
    background-color: #10dfb7;

}

.node circle.noun{
  /*fill: #c30;
  stroke: #c30;*/
  stroke:#ed258f;
  fill: #ed258f;
  
 /* stroke: #E50E50; bright pink
  fill: #E50E50;*/
}

.c8,.c24,.c14 {
  stroke-dasharray:7
}

.c1,.c25 {
  marker-end:url(#arrowBack)
}

.c4,.c18 {
  marker-end:url(#arrow)
}
circle.adj {
  /*stroke: #0070AA;
  fill: #0070AA;*/
    stroke:#4f7ff8;
    fill:#4f7ff8;
}
circle.verb {
  /*stroke: #bc74e9;
  fill: #bc74e9;*/
  /*stroke: #006F6F; green
  fill: #006F6F;*/
  /*stroke: #E99911;
  fill: #E99911;*/
  stroke:#10dfb7;
  fill:#10dfb7;
}
circle.adv {
  /*stroke: #0c3;
  fill: #0c3;*/
  /*stroke: #5D5AAA; purple
  fill: #5D5AAA;*/
  /*stroke: #C95999;
  fill: #C95999;*/
  stroke:#ffc107;
  fill:#ffc107;
}
.node,.anchorNode text {
  font: 14px sans-serif;
  cursor: pointer;
}
.big text {
  font: 24px sans-serif;
  text-transform: capitalize;	
}
.speaker {
  cursor: pointer;
}
.link.c {
  stroke-dasharray:initial
}
.link {
  fill: none;
  stroke: #eee;
  stroke-width: 2.5px;
}

@media screen and (max-width: 425px) {
  .node,.anchorNode text {
    font: 12px sans-serif;
    cursor: pointer;
  }
  .big text {
    font: 18px sans-serif;  
  }
  .link {
    stroke-width: 1.5px;
  }
} 
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}




.App {
/*background: #fefefe url('./assets/dot.svg') repeat 0 0;*/
}
 

.hidden {
  display: none !important;
}

#joinButton {
  cursor: pointer;
  background: linear-gradient(to right, #007bff, #4f7ff8);
}

.App-header {
  display: flex;
  justify-content: space-between;
}


.round-button {
  // padding: 15px;
  // font-family: 'Oswald',sans-serif;
  border-radius:8px;
  padding: 5px 10px 8px 10px;
  cursor: pointer;

}

.donate-button {
  margin-top:12px;
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 600;
  background-color: #007bff;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 0.5px #ccc; 
}
.clear {
  background-color: transparent;
  border-color: transparent;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-intro {
  font-size: large;
}
.suggestion-item {
cursor: pointer;
letter-spacing: 1px;
width: 240px;
margin: 0;
background: white;
/*color: #272727;*/
color: #999;

height: 26px;
padding: 10px 40px 10px 20px;
font-family: "Alternate Gothic No.2", Impact, 'Arial Narrow', Futura, sans-serif;
text-transform: uppercase;
font-size: 24px;
vertical-align: baseline;
  display: block;
/*  float: left;
  position: relative;
*/
  left: 0px; /*used to be 80px*/
  top: 0px; /*used to be -5px */
  outline: none;
}
.search-suggest {
  position: absolute;
  z-index:1000;
  padding:0px;
  margin:0px;
}

.search-suggest > li {
  display:block;
  background-color: white;
}
.bold {
  font-weight:bold;
}

.definition {
  display:block;
}

.banner-side-example {
  font-style:italic;
  margin-top:5px;
}

.banner-side-bottom {
  position: absolute; left:-10px; bottom:-10px;
}

#banner-side {
  width:200px;
}

.display-none {
  display: none
}
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.purple {
  color: #fff;
  background-color: #bc69e4;
}

.purple:hover {
  color: #bc69e4;
  background-color: #fff;
}

.green {
  color: #fff;
  background-color: #34a853;
}

.green:hover {
  background-color: #1e8e3e;
}

.red {
  color: #fff;
  background-color: #ea4335;
}

.red:hover {
  background-color: #c4211f;
}

.yellow {
  color: #fff;
  background-color: #fbbc04;
}

.yellow:hover {
  background-color: #f29900;
}

.blue {
  color: #fff;
  background-color: #4285f4;
}

.blue:hover {
  background-color: #1967d2;
}

.inverted-green {
  background-color: #fff;
  color: #34a853;
}

.inverted-red {
  background-color: #fff;
  color: #ea4335;
}

.inverted-yellow {
  background-color: #fff;
  color: #fbbc04;
}

.inverted-blue {
  background-color: #fff;
  color: #4285f4;
}

body {
/*background-color: #EDEDED;*/
/*background-color: #f1f1f1;*/
font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
}

#toolbar {
/*height: 35px;
width: 100%
background-color: #3D3D3D;
background-image: url(https://b9.sustatic.com/_DpBT92r-8P7_RjkjFvSWw),-webkit-linear-gradient(top,#3D3D3D 0%,#212121 100%);
-webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, .25);
-moz-box-shadow: 0 2px 3px rgba(0,0,0,.25);
box-shadow: 0 2px 3px rgba(0, 0, 0, .25);
*/

/**** NEW STYLE ****/
height: 90px;
width: 100%;
background-color: #3D3D3D;
background-image: url(https://b9.sustatic.com/_DpBT92r-8P7_RjkjFvSWw),-webkit-linear-gradient(top,#3D3D3D 0%,#212121 100%);
-webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, .25);
-moz-box-shadow: 0 2px 3px rgba(0,0,0,.25);
box-shadow: 0 3px 10px rgba(0, 0, 0, .25);
background: url('https://www.waze.com/images/body_bg.png') repeat-x;

}

ul {
display: block;
-webkit-margin-before: 1em;
-webkit-margin-after: 1em;
-webkit-margin-start: 0px;
-webkit-margin-end: 0px;
-webkit-padding-start: 40px;
display: inline;
}

li {
    display: inline;
    list-style-type: none;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 12px;
  padding-left: 22px;

}

a {
  text-decoration: none;
}


#toolbar-left {
  display: none;
}

#toolbar-right{
display: block;
/*background-image: url("https://www.waze.com/images/nav/logo_new.png");*/
/*content: "We Define."*/
background-repeat: no-repeat;
height: 90px;
float: left/*absolute*/;
margin: 18px 11px 0px 10px;
position: relative;
left: 160px;

}

#toolbar-right li{
  font-family: 'Varela Round', sans-serif;
font-size: 55px;
color: white;

}

#banner-side-searcher-result {
  text-align: left;
  overflow: auto;
  height: 275px;
}

.content-searcherwords {
  display: flex;
  justify-content: space-between;
}

.content-searcherwords a {
color: #6d6e71;
line-height: 160%;
}


#toolbar-right li.caption {
  font-family: 'Varela Round', sans-serif;
font-size: 14px;
color: #B7E1ED;
width: 200px;
text-wrap:normal;
display: block;
position: absolute;
top: 18px;
left: 320px;
font-weight: 800;
}
#toolbar-left .tb-button-text {
  padding-left: 3px;
  line-height: 35px;
  /*color: #CCC;*/
  color: #aaa;
  text-shadow: 0 -1px 1px black;


}
.searcherResult {
  font-style:italic;
  margin-top:5px;
}
/*** catchy button ***/
.btn.primary.cta {
  
min-width: 280px;
position: relative;
top: -15px;
left: 100px;
line-height: 75px;
border-radius: 60px;
font-size: 30px;
white-space: nowrap;
-moz-box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2),0 0 0 10px #B8B8B8;
-webkit-box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2),0 0 0 10px #B8B8B8;
-o-box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2),0 0 0 10px #B8B8B8;
-ms-box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2),0 0 0 10px #B8B8B8;
box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2),0 0 0 10px #B8B8B8;
}

.btn.primary {
color: white;
text-shadow: 0 1px 0 rgba(0, 0, 0, 0.29);
font-weight: bold;
border: 1px solid #9D3021;
background-color: #EB4924;
background-image: linear-gradient(bottom,#B32C0D 0%,#EB4924 100%);
background-image: -o-linear-gradient(bottom,#B32C0D 0%,#EB4924 100%);
background-image: -moz-linear-gradient(bottom,#B32C0D 0%,#EB4924 100%);
background-image: -webkit-linear-gradient(bottom,#B32C0D 0%,#EB4924 100%);
background-image: -ms-linear-gradient(bottom,#B32C0D 0%,#EB4924 100%);
background-image: -webkit-gradient(linear,left bottom,left top,color-stop(0,#B32C0D),color-stop(1,#EB4924) );


/* blue
border: 1px solid #113F99;
background-color: #0096D6;
background-image: linear-gradient(bottom,#113F99 0%,#0096D6 100%);
background-image: -o-linear-gradient(bottom,#113F99 0%,#0096D6 100%);
background-image: -moz-linear-gradient(bottom,#113F99 0%,#0096D6 100%);
background-image: -webkit-linear-gradient(bottom,#113F99 0%,#0096D6 100%);
background-image: -ms-linear-gradient(bottom,#113F99 0%,#0096D6 100%);
background-image: -webkit-gradient(linear,left bottom,left top,color-stop(0,#113F99),color-stop(1,#0096D6) );
*/

}

.btn {
min-width: 87px;
margin: 0;
padding: 10px 10px;
display: inline-block;
cursor: pointer;
color: #888;
line-height: 18px;
text-align: center;
text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.39);
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 11px;
border: 1px solid #CECECF;
}

#banner {
max-width: 1180px;
min-width: 960px;
height: 468px;
margin: 0;
position: relative;
}

.definition {
border: 1px solid #ECECEC;
border-radius: 10px 10px 10px 10px;
display: block;
max-width: 200px;
z-index: 500;
position: absolute;
top: 20px;
left: 70px;
padding: 10px 10px 5px 10px;
margin: 0;
}

.definition ul{
  margin: 0;
  padding: 0;
}

.definition li{
font-family: "Helvetica Neue", "Helvetical", Arial, sans-serif;
font-style: normal;
font-size: 12px;
display: inline-block;
z-index: 500;
color: #666;
padding-left: 0;
}

.definition li span {
padding: 0px 3px 0px 3px;
background: white;
display: inline-block;
color: #666;
text-align: left;
line-height: 12pt;
}

.definition li span .byline {
/*padding: 0px 0px 5px 0px;*/
margin: 0;
font-family: "Alternate Gothic No.2", Impact, 'Arial Narrow', Futura, sans-serif;
text-transform: uppercase;
font-weight: normal;
font-size: 16px;
color: #939598;
line-height: 1;
vertical-align: baseline;
outline: none;
display: block;
margin-bottom: 5px;
}

.definition li span .byline span {
color: #EF3E42;
font-size: 12px;
vertical-align: top;
}

#banner-feature {
width: 100%;
height: 100%;
background: white;
overflow: hidden;

/*box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);*/
-webkit-box-shadow:  0 5px 20px -10px rgba(0, 0, 0, 0.2),  0 -5px 20px -10px rgba(0, 0, 0, 0.2);
-moz-box-shadow:  0 5px 20px -10px rgba(0, 0, 0, 0.2),  0 -5px 20px -10px rgba(0, 0, 0, 0.2);
box-shadow:  0 5px 20px -10px rgba(0, 0, 0, 0.2),  0 -5px 20px -10px rgba(0, 0, 0, 0.2);


-webkit-border-radius: 4px;
-moz-border-radius: 4px;
border-radius: 4px;
}

#banner {
  margin: auto;
  z-index: 0;
}
#chart {
  
}

#banner-side {
  width: 200px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 450;
  /*background: black;*/
  border-left: 1px solid #ebebeb;


/*
  background-image: -webkit-linear-gradient(top, white 0%, #EBEBEC 100%);
background-image: -ms-linear-gradient(top, white 0%, #EBEBEC 100%);
background-image: linear-gradient(top, white 0%, #EBEBEC 100%);
*/
/*background: #ebebec;*/
}

#banner-side-top {
  top: 0;
  right: 0;
  margin: 5px 0px 10px;
}

/*  PREVIOUS DESIGN
.byline {
font-size: 36px;
color: #939598;
line-height: 1;
font-weight: normal;
font-family: "StrangeloveTextRegular", "Alternate Gothic No.2", Impact, "Ariel Narrow", Helvetica, Arial, sans-serif;
padding-left: 19px;
}
*/

/* BIG HEADLINE VERSION
.byline {
  padding: 10px 0 8px 0;
margin-bottom: 0;
overflow: hidden;
color: #3B3B3B;
font-size: 22px;
line-height: 1.3;
font-weight: normal;
text-shadow: 0 0 0 transparent, 1px 1px 2px white;
font-family: 'Oswald',sans-serif;
margin-left: 10px;
}
*/

#banner-side .byline {
  /*background-color: #2a6584;*/
  /*background-color: #ED258F;*/
  height: 25px;
padding: 0 10px;
margin-bottom: 4px;
display: block;
line-height: 25px;
font-size: 14px;
font-weight: normal;
text-shadow: 0 0 0 transparent, 0 0 1px rgba(0, 0, 0, 0.1);
color: white;
font-family: 'Oswald',sans-serif;
margin-left: 10px;
-webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, .25);
-moz-box-shadow: 0 2px 3px rgba(0,0,0,.25);
box-shadow: 0 2px 3px rgba(0, 0, 0, .25);
}

.byline span{
  color: #EF3E42;
}

.manifesto {
display: block;
width: 170px;
padding: 10px;
border: none;
text-decoration: none;
color: #D6DEE0;
opacity: 1;
}

.manifesto span {
font-size: 12px;
display: block;
line-height: 1.3;
margin-top: 5px;
}

.manifesto.wl span {
  font-size: 12px;
  color: #bc69e4;
  line-height: 1.3;
  margin-top: 5px;
}
.vocab-def {
  position: absolute;
  left: 200px;

}
.wordlist-div {
  position: absolute;
  left: 200px;
}
#vocabDefs {
  margin-bottom: 8px;
text-shadow: 0 0 0 transparent, 1px 1px 1px white;
margin: 0;
font-size: 13px;
line-height: 18px;
overflow: hidden;
text-overflow: ellipsis;
color: #6D6E71;
}
#banner-side-definition {
  margin-bottom: 8px;
text-shadow: 0 0 0 transparent, 1px 1px 1px white;
margin: 0;
font-size: 13px;
line-height: 18px;
overflow: hidden;
text-overflow: ellipsis;
color: #6D6E71;
height: 80px;
}

#banner-side-definition:first-letter{
  text-transform: uppercase;
}

#banner-side-bottom {
  right: 0;
  background: transparent;
}



#menu-primary {
  position: relative;
  top: 0;
  left: 0;
}

#menu-primary .wrap {
max-width: 1180px;
min-width: 960px;
margin: 0;
z-index: 600;
/*background: #D1D3D4;*/
position: relative;
height: 30px;

/* white
background: -moz-linear-gradient(top,white 80%,#F6F6F6 100%);
background: -webkit-gradient(linear,left top,left bottom,color-stop(80%,white),color-stop(100%,#F6F6F6));
background: -webkit-linear-gradient(top,white 80%,#F6F6F6 100%);
background: -o-linear-gradient(top,white 80%,#F6F6F6 100%);
background: -ms-linear-gradient(top,white 80%,#F6F6F6 100%);
background: linear-gradient(to bottom,white 80%,#F6F6F6 100%);
*/

/* same as top toolbar */
background-color: #3D3D3D;
background-image: url(https://b9.sustatic.com/_DpBT92r-8P7_RjkjFvSWw),-webkit-linear-gradient(top,#3D3D3D 0%,#212121 100%);
-webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, .25);
-moz-box-shadow: 0 2px 3px rgba(0,0,0,.25);
box-shadow: 0 2px 3px rgba(0, 0, 0, .25);


/* gray 
border-top: 1px solid white;
background-color: #A6A7A8;
background-image: -moz-linear-gradient(top, #BCBEC0 0%, #979797 100%);
background-image: -webkit-linear-gradient(top, #BCBEC0 0%, #979797 100%);
background-image: -ms-linear-gradient(top, #BCBEC0 0%, #979797 100%);
background-image: linear-gradient(top, #BCBEC0 0%, #979797 100%);
*/

text-align: center;
border-bottom: 1px solid #D4D4D4;
}

#menu-primary .menu {
  max-width: 1180px;
  min-width: 960px;
  background: #D1D3D4;
  zoom: 1;
  height: 1%;
  position: relative;

  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
-moz-box-shadow: 0 1px 2px rgba(0,0,0,.25);
box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
}

#menu-primary ul 
{margin:0;padding:0 0 0 50px;list-style:none;line-height:1.0;overflow:hidden;height:38px;border-bottom: 1px solid #babbbe;}

/*Top Level*/
#menu-primary li{float: left;width:172px;position:relative;margin-right:19px; } 
#menu-primary li a{display:block;position:relative;
/*  color: #565C5F; for white background*/
  color: #8e8e8e;/*same as top toolbar */
  border: none;border-top: 1px solid #b5b5b6;
  text-decoration:none;outline:none;margin-top:7px;padding-top:6px;
  font-weight:normal;font: normal normal normal 12px/12px Arial, Verdana, sans-serif; text-transform: uppercase;}
#menu-primary li a:hover {text-decoration: none;color: #D6DEE0;border-top: 1px solid #ef3e42;}
#menu-primary li li a:hover {text-decoration: none;color: #565C5F;border-top: none;border-bottom:1px solid #ef3e42;}

.page-divider {
  height: 1px;
width: 100%;
background-color: #FEFEFE;
border-top: 1px solid #DEDEDE;
text-align: center;
z-index: 1;
}
#main {
  max-width: 1130px;
min-width: 910px;
margin: auto;
padding: 0 0 25px 50px;
clear: both;
overflow: hidden;
/*background: #F1F1F1;  shadow on top will disappear if we include this */
position: relative;
margin-top: 0;
padding-bottom: 0;
border-top: 1px solid #DEDEDE;

-webkit-border-radius: 4px;
-moz-border-radius: 4px;
border-radius: 4px;
-webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.07);  
-moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.07);    /* used to be 0 1px 2px  and 0 3 5 with no inset*/
box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.07);
}

#main #content {
width: 64%;
border-right: 1px solid #BABBBE;
float: left;
padding: 27px 21px 27px 0;
/*background: transparent url("../images/column.gif") repeat-y scroll 49% 0;
*/}

#main .post {
width: 45%;
float: left;
display: inline;
/*margin-right: 7.934336525307797%;*/
margin-bottom: 19px;
overflow: hidden;
margin: 0;
font-size: 0.8em;
line-height: 160%;
color: #666;
position: relative;
line-height: 160%;
}

#post-5068 {
  position: relative;
  left: 7.9%
}
.home-cat-name {
font-size: 19px;
line-height: 1;
display: inline-block;
padding: 5px 4px 5px 0px;
text-transform: uppercase;
font-size-adjust: 0.5;
color: #58595B;
outline: 0;
text-decoration: none;
border: none;
font-family: "Alternate Gothic No.2", "Arial Narrow", Helvetica, Arial, sans-serif;
}

.home-cat-name:before {
  content: "|";
  color: #2a6584;
}
.readmore {
  padding-left:2px;
  text-transform: uppercase;
/*  background: transparent url(../images/more-arrow.gif) no-repeat scroll center right;
*/  padding-right: 13px;display: inline-block;text-decoration: none;border: none;font-family: "Alternate Gothic No.2", "Arial Narrow", Helvetica, Arial, sans-serif;font-size: 18px;line-height: 24px;color: white;font-weight: normal;margin-top: 10px;background-position-y: 6px;}

.content-relatedwords a {
  border-bottom: 1px dotted #EF3E42;
color: #EF3E42;
line-height: 160%;
}

.entry-summary {
  margin-top: 7px;
  margin-bottom: 7px;
}

#sidebar-primary {
  font-size: 0.8em;
line-height: 160%;
color: #666;
width: 33%;
float: right;
padding-top: 27px;
padding-bottom: 17px;
}

#sidebar-primary .widget {
clear: both;
}

.widget-wrap {
padding: 27px 5px 0 5px;
/*max-width: 355px;*/
}

#sidebar-primary .widget-title {
font-size: 19px;
line-height: 1;
display: inline-block;
padding: 5px 4px 5px 0px;
text-transform: uppercase;
font-size-adjust: 0.5;
color: #58595B;
outline: 0;
text-decoration: none;
border: none;
font-family: "Alternate Gothic No.2", "Arial Narrow", Helvetica, Arial, sans-serif;
}

.widget-title:before {
  content: "|";
  color: #2a6584;
}

#footer {
max-width: 1103px;
min-width: 883px;
margin: 0;
padding: 25px 27px 0 50px;
overflow: hidden;
clear: both;
font-size: 12px;
color: #CCC;
float: left;
}

#footer a {
color: #CCC;
text-decoration: none;
border: none;
}

/***********USAGE*************/
.usage#banner-feature {
  background-color: white;
  padding: 6px;
  display: block;
  float: left;
}

.usage #banner-wrap {
  background-color: black;
  width: 100%;
  height: 100%;
}


.usage .description-text {
  color: #f6f6f6;
  font-family: serif;
  font-size: 20px 20px 15px 20px;
font-family: 'Arvo','Merriweather',  Georgia;
font-size: 23px;
padding: 20px
}

.usage .description-info {
 color: #d10451;
 font-family: 'Merriweather', 'Arvo', Georgia;
 font-size: 12px;
 padding: 0 20px 20px 20px;
}

.usage .description-info a {
  text-decoration: none;
  color: #d10451;
  font-family: 'Merriweather', 'Arvo', Georgia;
  font-size: 12px;
}

.logo-test {
  width: 40px;
  height: 8px;
  background-color: white;
  border: 3px solid red;
  border-radius: 10px 10px;
}

.logo-book {
  width: 75px;
  height: 40px;
  float: left;
/*  background-image: url("../images/logo-booksmall.png")
*/}

.content-primary {
  border-top: 3px solid white;
}



#tree-icon {
width: 16px;
height: 16px;
display: block;
/*background: #2a6584;*/
background: #4f7ff8;
cursor: pointer;
text-indent: -9999px;
-webkit-border-radius: 20px;
-moz-border-radius: 20px;
-o-border-radius: 20px;
border-radius: 20px;
box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
position: absolute;
bottom: 20px;
left: 50px;


/*
position: absolute;
bottom: 12px;
left: 50px;
font-family: "Varela Round";
color: #2a6584;
font-size: 40px;
font-weight: 100;

width: 30px;
height: 30px;
display: block;
cursor: pointer;
-webkit-border-radius: 30px;
-moz-border-radius: 30px;
-o-border-radius: 30px;
border-radius: 30px;
box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
padding-bottom: 0px;
*/
}

#star-icon {
width: 14px;
height: 14px;
display: block;
background: #eee;
cursor: pointer;
text-indent: -9999px;
-webkit-border-radius: 20px;
-moz-border-radius: 20px;
-o-border-radius: 20px;
border-radius: 20px;
box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
position: absolute;
bottom: 20px;
left: 80px;
    border-color: #4f7ff8;
    border-width: 1.5px;
    border-style: solid;
    opacity: 0.6;

/*
position: absolute;
bottom: 12px;
left: 90px;
font-family: "Valera Round";
color: silver;
font-size: 50px;

width: 30px;
height: 30px;
display: block;
cursor: pointer;
-webkit-border-radius: 50px;
-moz-border-radius: 50px;
-o-border-radius: 50px;
border-radius: 50px;
box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
padding-bottom: 0px;
*/
}



@font-face {
 font-family: 'Trump Gothic East';
src: url('https://born4banned.googlecode.com/files/trumpgothiceastmedium.eot');
src: url('https://born4banned.googlecode.com/files/trumpgothiceastmedium.woff') format('woff'),
url('https://born4banned.googlecode.com/files/trumpgothiceastmedium.ttf') format('truetype'),
url('https://born4banned.googlecode.com/files/trumpgothiceastmedium.svg') format('svg');
font-weight: bold; font-style: normal;}

@font-face {
  font-family: 'Alternate Gothic No.2';
  src: url(./assets/ALTGOT2N.ttf);
}

@font-face {
  font-family: "StrangeloveTextRegular";
  src: url(./assets/strangelove-text-webfont.ttf);
}

/*@font-face {
  font-family: "Wendy";
  src: url('../files/wendy-lp-medium1.ttf');
}*/

/*@font-face {
  font-family: "quantrnd";
  src: url('../files/quantrnd.ttf');
}*/


@media only screen and (max-device-width: 480px), only screen and (min-device-width: 640px) and (max-device-width: 1136px) and (-webkit-min-device-pixel-ratio: 2) {
    /* iPhone only */
    #banner-side-top {display:none;}
    #banner-side {border: 0;}
/*    a#icon-next{ top:35px; left: auto;}
*/    
}

@media screen and (max-width: 425px) {

  #banner-feature {
    box-shadow: none;
  }
  #tree-icon {
    left:30px;
  }
  #star-icon {
    left:60px;
  }
  #banner {
    min-width: 370px;
    max-width: 375px;
    width: 375px;
    height: 375px;
    overflow: hidden;
  }
  #main {
    padding: 0px;
    min-width: 370px;
    max-width: 370px;
    width: 370px;
    font-size: 67%;
  }

  #definitions {
    margin-bottom: 20px;
  }

  #main #content {
    width: 185px;
    padding: 15px 10px 15px 0;
  }

  #main .post {
    width: 90%;
    font-size: 95%;
  }

  #post-5068 {
    position: relative;
    left: 10px;
  }
  #sidebar-primary {
    width: 45%;
    padding-top: 15px;
  }

  .readmore {
    font-size: 15px;
    line-height: 17px;
  }

  #chart {
  }

  .entry-summary {
    line-height: 120%;
  }

  .content-relatedwords {
    line-height: 75%;
  }

  #banner-side {
    display: none;
  }
}
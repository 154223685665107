.node circle {
  cursor: pointer;
  /*fill: #fff;*/
  stroke: steelblue;
  stroke-width: 2px;
}

.vx1 { max-width: 80vw; background-color:  #ff3f4a; }
.vx2 { max-width: 80vw; background-color:  #ff942c; }
.vx3 { max-width: 80vw; background-color:  #e9c829; }
.vx4 { max-width: 80vw; background-color:  #a0da37; }
.vx5 { max-width: 80vw; background-color:  #63b9e3; }


circle.vx1 { fill:  #ff3f4a; stroke:  #ff3f4a;}
circle.vx2 { fill:  #ff942c; stroke:  #ff942c;}
circle.vx3 { fill:  #e9c829; stroke:  #e9c829;}
circle.vx4 { fill:  #a0da37; stroke:  #a0da37;}
circle.vx5 { fill:  #63b9e3; stroke:  #63b9e3;}

.noun {
  /*background-color: #c30;*/
  background-color:#ed258f;
}
.adj {
    /*background-color: #0070AA;*/
      background-color: #4f7ff8;

}

.adv {
  /*background-color: #C95999;*/
    background-color: #ffc107
}
.verb {
    /*background-color: #E99911;*/
    background-color: #10dfb7;

}

.node circle.noun{
  /*fill: #c30;
  stroke: #c30;*/
  stroke:#ed258f;
  fill: #ed258f;
  
 /* stroke: #E50E50; bright pink
  fill: #E50E50;*/
}

.c8,.c24,.c14 {
  stroke-dasharray:7
}

.c1,.c25 {
  marker-end:url(#arrowBack)
}

.c4,.c18 {
  marker-end:url(#arrow)
}
circle.adj {
  /*stroke: #0070AA;
  fill: #0070AA;*/
    stroke:#4f7ff8;
    fill:#4f7ff8;
}
circle.verb {
  /*stroke: #bc74e9;
  fill: #bc74e9;*/
  /*stroke: #006F6F; green
  fill: #006F6F;*/
  /*stroke: #E99911;
  fill: #E99911;*/
  stroke:#10dfb7;
  fill:#10dfb7;
}
circle.adv {
  /*stroke: #0c3;
  fill: #0c3;*/
  /*stroke: #5D5AAA; purple
  fill: #5D5AAA;*/
  /*stroke: #C95999;
  fill: #C95999;*/
  stroke:#ffc107;
  fill:#ffc107;
}
.node,.anchorNode text {
  font: 14px sans-serif;
  cursor: pointer;
}
.big text {
  font: 24px sans-serif;
  text-transform: capitalize;	
}
.speaker {
  cursor: pointer;
}
.link.c {
  stroke-dasharray:initial
}
.link {
  fill: none;
  stroke: #eee;
  stroke-width: 2.5px;
}

@media screen and (max-width: 425px) {
  .node,.anchorNode text {
    font: 12px sans-serif;
    cursor: pointer;
  }
  .big text {
    font: 18px sans-serif;  
  }
  .link {
    stroke-width: 1.5px;
  }
} 
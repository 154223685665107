.rc-menu-item {
  background-color: white;
  cursor: pointer;
  color:#bc69e4;
}

.rc-menu-submenu > div.rc-menu-submenu-title {
  background-color: white;
  cursor: pointer;
  color:#bc69e4;
}

li.rc-menu-item-active, .rc-menu-submenu-active > div.rc-menu-submenu-title {
  background-color:#bc69e4;
  color: white;
}
.rc-menu-item-selected {
  background-color: #eaf8fe;
}

.demo-menu {
  margin-left: 30px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 4px #d9d9d9;
  border-radius: 3px;
  z-index:9;
}

.inline-menu {
  background-color: white;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 4px #d9d9d9;
  border-radius: 3px;
  z-index:9;
}

li {
  font-family: oswald;
  text-transform: uppercase;  
  font-size: 14px;  
}

.current-wordlist-container {
  height:100%;
  display:flex;
  padding: 0px 24px;
  align-items: center;
  cursor: pointer;
  color:#bc69e4;

}
.current-wordlist-container:hover {
  color:white;
  background-color:#bc69e4;
}

.current-wordlist-container:hover div {
  color:white;
  background-color:#bc69e4;
}

.current-wordlist {
  position: relative;
  margin-right:20px;
  background: white;
  border-style: solid;
  min-width: 110px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: oswald;
  text-transform: uppercase;
}

.rc-menu {
  border: none;
}

.current-wordlist-container:after {
  content: "";
  border: 10px solid transparent;
  border-top-color: #bc69e4;
  transform: translate(0, 4px);
}

.current-wordlist-container:hover:after {
  border-top-color: white;

}
li.rc-menu-item i {
  display: none;
}

@media screen and (max-width:425px) {
  .current-wordlist {
    margin-right: 4px;
    min-width: 64px;
    max-width: 64px;
    font-size: 12px;
  }

  .demo-menu {
    margin: 0px 10px;
  }
  
  .current-wordlist-container {
    padding: 0px 10px;
  }
}
.App-header-name {
  /*color: #B7E1ED;*/
  color: #4f7ff8;
  font-size: 45px;
  position: relative;
  top: 5px;
  left: 0px;
}

.App-header-caption {
  color: white;
  font-size: 14px;
  font-weight: 500;
  display:block;
  background-color: #4f7ff8;
  border-radius: 6px;
   margin-top: 16px;
   margin-right: 0px;
    padding-top: 12px;
    /*padding-left: 26px;*/
    padding-bottom: 12px;
    text-align: center;
    width:150px;
    float:right;
}

.App-header {
  font-family: 'Varela Round', sans-serif;
  /*background-color: #323232;*/
  height: 70px;
  padding: 0px;
  
  color: white;
  max-width: 1180px;
  min-width: 860px;
  margin:auto;
}

@media screen and (max-width:425px) {
  .App-header {
    height: unset;  
  }

  .App-header {
    max-width: 375px;
    min-width: 375px;
  }
}
.App-header-holder {
    /*background-color: #323232;*/

}

#flickrlist li {
list-style: none;
margin: 0;
/*padding: 2px 2px 2px 2px;*/
float: left;
display: block;
width: 75px;
height: 75px;
border: 1px solid #CCC;
margin-right: 8px;
margin-bottom: 8px;
padding: 0px;
position: relative;
overflow: hidden;

}
#flickrlist ul {
list-style: square;
}

#flickrlist li a {
margin: 0;
padding: 0;
display: block;
width: 75px;
height: 75px;
float: left;
border: none;
text-decoration: none;
}

#flickrlist li a img{
  width: 120px;
  height: auto;
  position: absolute;
  top:0;
  left:0;
}

li.selected-item{
  background-color: #B7E1ED;
}

.quiz-button {
  font-family: 'Oswald',sans-serif;
  font-size: 18px;
  padding: 4px 20px 6px 18px;
  margin-left: 40px;
  border: solid 0.5px #ccc;
  &:focus {
    border: solid 0.5px #ccc;
  }
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */

h1.header-logo-text {
  /*background: transparent url("../images/logo-wendy.png") no-repeat center 0;*/
  height: 70px;
  width: 200px;
  text-indent: -9999px;
  float: left;
  position: relative;
  /*top: -10px;*/
  left: -20px;
  font-family: 'Wendy';
  font-size: 55px;
  color: #555;
  font-weight: 200;
  background: transparent url(./assets/lookup.png) no-repeat center 0; 
}

.header-content {
  max-width: 1180px;
  min-width: 860px;
  /*padding: 35px 50px 0; */ /*used to be 50 50 0*/
  /*height: 75px;*/
  padding: 20px 50px;
  height: 50px;
  border-bottom: 1px solid #DFDFDF;
  margin: auto;
  display: flex;
}

.header-logo {
  display: block;
  float: left;
  width: 200px;
}

.header-content .btn .search2 .search-text {
  position: relative;
  top:0px;
  left: 0px;
}

/* black big header banner */
.header-content-wrap.clean {
background: #373737 url(http://b9.sustatic.com/nxPUscdEbS9Bu2gmPf5Dxg) no-repeat center;
border-bottom: 1px solid white;
}

.header-content-wrap.short {
height: auto;
min-height: 59px;
max-height: 244px;
padding: 20px 0;
}
/***************************/

.header-content .search2 {

  
}

.word-list-selector {
  margin-left:200px;
}

.search-form {
  display: inline-block;
  width: 304px;
}
.search-text{
/*border: 3px solid #A9ADA8;
border-radius: 18px 18px 18px 18px;
*/

/*border: 3px solid #BDBDBD;
border-radius: 10px;*/
/*border: 2px solid #1cadce;*/
border: 2px solid #4f7ff8;
border-radius: 10px;
letter-spacing: 1px;
width: 240px;
margin: 0;
background: white;
/*color: #272727;*/
color: #999;

height: 26px;
padding: 10px 40px 10px 20px;
font-family: "Alternate Gothic No.2", Impact, 'Arial Narrow', Futura, sans-serif;
text-transform: uppercase;
font-size: 24px;
vertical-align: baseline;
  display: block;
/*  float: left;
  position: relative;
*/
  left: 0px; /*used to be 80px*/
  top: 0px; /*used to be -5px */
  outline: none;


/*
padding: 0 10px 0 15px;
height: 28px;
font-size: 18px;
line-height: 28px;
font-family: "Alternate Gothic No.2", Impact, 'Arial Narrow', Futura, sans-serif;
text-transform: uppercase;
color: #999;
font-weight: normal;
background: white;

font-size: 18px;
  height: 28px;
  width: 150px;
  vertical-align: baseline;
  display: block;
  float: left;
  position: relative;
  left: 80px;
  top: 5px;
  outline: none;
*/

}

.header-content .search2 .search-text:focus {
border: 3px solid #A9ADA8;
border-radius: 18px 18px 18px 18px;
box-shadow: 0px 0px 10px #6EA2DE;
}

.search-submit {
  display: none;
}

/*************ICONS***************/
a.next-word {
  // background: url('/common/img/sprite-icons3.png') no-repeat;
  /*background: url('https://freeiconshop.com/wp-content/uploads/edd/shuffle-flat.png') no-repeat;*/
  background: url('/common/img/shuffleiconbluesame1.png') no-repeat;
  /*background: url('http://www.thehappiestmama.com/wp-content/uploads/2020/12/nextword.png');*/
    background-size: 50px;
    z-index: 9999;
  /*background-position-x:0px;
  background-position-y:-1321px;*/
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
  zoom: 1;
  overflow: hidden;
  text-indent: -9999em;
  height: 50px;
  width: 50px;
  cursor: pointer;
  }

a.next-word.hover:hover {
  /*background-position-x:0px;
  background-position-y:-740px;*/
  /*transform: rotate(-70deg);*/
  /*box-shadow: 2px 5px 32px rgba(0,0,0.3);*/
    -webkit-transform: rotate(-15deg);
     -moz-transform: rotate(-10deg);
       -o-transform: rotate(-10deg);
      -ms-transform: rotate(-10deg);
          transform: rotate(-10deg);
          /*opacity: 0.85;*/
}


@media screen and (max-width:425px) {
  div.header-content {
    padding: 10px;
    margin: none;
    max-width: 375px;
    min-width: 375px;
    width: 375px;
  }

  .search-form {
    width: unset;
  }

  .search-text {
    width: 107px;
    height: 26px;
    font-size: 16px;
  }
  a.next-word {
    margin-left: 9px;
  }

  a.arrow {
  background: url('http://www.thehappiestmama.com/wp-content/uploads/2013/01/sprite-icons3.png') no-repeat;
  background-position-x:0px;
  background-position-y:-1321px;
  right: 0;
  display: block;
  margin-top: -35px;
  position: absolute;
  top: 50%;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  overflow: hidden;
  text-indent: -9999em;
  height: 70px;
  width: 70px;
  cursor: pointer;
  opacity: 0.3;
  transform: scale(0.5);
  right: -17.5px;
  }

  a.arrow:hover {
    background-position-x:0px;
    background-position-y:-740px;
  }
} 